html, body, body > #root, #root > div {
    height: 100%;
    width: 100%;
    font-family: "Roboto", serif;
}

h1, h2, h3 {
    font-family: "Roboto Slab", serif !important;
}

.App {
    display: flex;
}

::-webkit-scrollbar-track {
    border-radius: 999999999px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    border-radius: 999999999px;
    width: .75em;
    height: .75em;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 999999999px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    background-color: #AC013A;
}
